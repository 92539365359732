.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-color: #4D8076;
  color: white;
  font-family: "Poppins", sans-serif;


  .text {
    display: flex;
    align-items: center;
    font-size: 2em;
    font-weight: 500;
    height: 100%;
    flex-grow: 1;
  }


  .footer {
    font-size: 1em;
    margin: 16px;
  }
}
